var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"grey","to":{name: 'admin.projects.create'}}},[_vm._v("Create")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.projects,"items-per-page":15},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"cursor-pointer text--blue",on:{"click":function($event){return _vm.handleClick(item,'projects')}}},[_vm._v(" "+_vm._s(item.name_hy)+" ")]),_c('v-col',{staticClass:"cursor-pointer text--blue",on:{"click":function($event){return _vm.handleClick(item,'projects')}}},[_vm._v(" "+_vm._s(item.name_ru)+" ")]),_c('v-col',{staticClass:"cursor-pointer text--blue",on:{"click":function($event){return _vm.handleClick(item,'projects')}}},[_vm._v(" "+_vm._s(item.name_en)+" ")])],1)]}},{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',[_c('img',{staticClass:"max-width150",attrs:{"src":("" + (item.image_url))}})])],1)]}},{key:"item.developer.name",fn:function(ref){
var item = ref.item;
return [(item.developer !== null)?_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClick(item.city, 'developers')}}},[_vm._v(" "+_vm._s(item.developer.name)+" ")])],1):_vm._e()]}},{key:"item.city.name_hy",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClick(item.city, 'cities')}}},[_vm._v(" "+_vm._s(item.city.name_hy)+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('project-delete-dialog',{attrs:{"project":item},on:{"deleted":_vm.handleRemoveProject}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }