<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn  color="grey" :to="{name: 'admin.projects.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="projects"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name="{ item }">
                <v-row class="flex-column">
                    <v-col class="cursor-pointer text--blue" @click="handleClick(item,'projects')">
                        {{ item.name_hy }}
                    </v-col>
                    <v-col class="cursor-pointer text--blue" @click="handleClick(item,'projects')">
                        {{ item.name_ru }}
                    </v-col>
                    <v-col class="cursor-pointer text--blue" @click="handleClick(item,'projects')">
                        {{ item.name_en }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.image_url="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col>
                        <img :src="`${item.image_url}`" class="max-width150"/>
                    </v-col>
                </v-row>
            </template>
            <template #item.developer.name="{ item }">
                <v-row class="cursor-pointer text--blue" v-if="item.developer !== null">
                    <v-col @click="handleClick(item.city, 'developers')">
                        {{ item.developer.name }}
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.city.name_hy="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClick(item.city, 'cities')">
                        {{ item.city.name_hy }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }} <!--TODO add day.js and default format -->
            </template>
            <template v-slot:item.actions="{ item }">
                <project-delete-dialog @deleted="handleRemoveProject" :project="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Project from '../../../models/Project';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import ProjectDeleteDialog from "../../../components/dialogs/ProjectDeleteDialog";

export default {
    name: "projects-index",
    components: {AdminDashboardLayout, ProjectDeleteDialog},
    data: function () {
        return {
            loading: false,
            projects: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Picture', value: 'image_url'},
                {text: 'Developer', value: 'developer.name'},
                {text: 'City', value: 'city.name_hy'},
                {text: 'Completed at year', value: 'completed_at_year'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClick(dataId, namePath) {
            this.$router.push({name: `admin.${namePath}.show`, params: {id: dataId.id}})
        },
        handleRemoveProject(id) {
            this.projects = this.$_.filter(this.projects, project => project.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.projects = await Project.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}

.max-width150{
    max-width: 150px;
}
</style>
